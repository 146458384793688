<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12" md="12">
                <v-card :elevation="1" color="#26c6da2b">
                    <v-card-text class="py-1">
                        <v-row dense>
                            <v-col cols="12" md="2">
                                <v-combobox label="Designation" dense outlined hide-details
                                    :items="$store.getters['designation/designations']" v-model="designation"
                                    item-text="name" item-value="id"
                                    :loading="$store.getters['designation/loadingDesignations']"
                                    @focus="$store.dispatch('designation/getDesignations')"
                                    class="custom-form"></v-combobox>
                            </v-col>

                            <v-col cols="12" md="2">
                                <v-combobox label="Department" dense outlined hide-details
                                    :items="$store.getters['department/departments']" v-model="department"
                                    item-text="name" item-value="id"
                                    :loading="$store.getters['department/loadingDepartments']"
                                    @focus="$store.dispatch('department/getDepartments')"
                                    class="custom-form"></v-combobox>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-combobox label="Section" dense outlined hide-details :items="sections"
                                    v-model="section" item-text="name" item-value="id"
                                    :loading="$store.getters['section/loadingSections']"
                                    class="custom-form"></v-combobox>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-combobox label="Line" dense outlined hide-details :items="lines" v-model="line"
                                    item-text="name" item-value="id" :loading="$store.getters['line/loadingLines']"
                                    class="custom-form"></v-combobox>
                            </v-col>

                            <v-col cols="12" md="2">
                                <v-combobox label="Shift" dense outlined hide-details
                                    :items="$store.getters['shift/shifts']" v-model="shift" item-text="name"
                                    item-value="id" :loading="$store.getters['shift/loadingShifts']"
                                    @focus="$store.dispatch('shift/getShifts')" class="custom-form"></v-combobox>
                            </v-col>

                            <v-col cols="12" md="2">
                                <v-combobox label="Employee" dense outlined hide-details v-model="employee"
                                    :items="$store.getters['employee/employees']"
                                    :loading="$store.getters['employee/loadingEmployees']" item-text="display_name"
                                    item-value="id" class="mb-1 custom-form" @focus="getEmployees"></v-combobox>
                            </v-col>

                            <v-col cols="6" md="2">
                                <v-menu :close-on-content-click="false" transition="scale-transition" offset-y
                                    min-width="auto">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field dense outlined hide-details v-model="date_from" label="Date From"
                                            v-on="on" class="custom-form"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="date_from"></v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col cols="6" md="2">
                                <v-menu :close-on-content-click="false" transition="scale-transition" offset-y
                                    min-width="auto">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field dense outlined hide-details v-model="date_to" label="Date To"
                                            v-on="on" class="custom-form"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="date_to"></v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col cols="6" md="1">
                                <v-btn elevation="1" small color="primary" dark style="margin-top:-2px"
                                    :loading="loading" @click="getReports">
                                    Search </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="records.length > 0">
            <v-col cols="12">
                <v-btn color="primary" class="white--text" small dense @click="print">
                    <v-icon left small dense> mdi-printer </v-icon>
                    Print
                </v-btn>
            </v-col>
            <v-col cols="12" v-if="employee == null || employee.id == undefined" id="reportContent">
                <v-simple-table dense class="record_table" v-for="(record, sl) in records" :key="sl">
                    <tr>
                        <th colspan="12" style="text-align:center;">{{ record.display_text }}</th>
                    </tr>
                    <template v-for="(department, sl2) in record.departments">
                        <tr>
                            <th colspan="12" style="text-align: left;color: #0277bd;">{{ department.name }}</th>
                        </tr>

                        <tr class="record_heading_tr">
                            <th>SL.</th>
                            <th>E-ID</th>
                            <th>Bio-ID</th>
                            <th>Name</th>
                            <th>Designation</th>
                            <th>Mobile</th>
                            <th>Shift</th>
                            <th>In</th>
                            <th>Out</th>
                            <th>Late</th>
                            <th>OT</th>
                            <th>Status</th>
                        </tr>

                        <tr v-for="attendance in department.attendances" :key="attendance.sl">
                            <td>{{ attendance.sl }}</td>
                            <td>{{ attendance.emp_id }}</td>
                            <td>{{ attendance.biometric_id }}</td>
                            <td style="text-align: left;">{{ attendance.employee_name }}</td>
                            <td style="text-align: left;">{{ attendance.designation_name }}</td>
                            <td style="text-align: left;">{{ attendance.employee_phone }}</td>
                            <td style="text-align: left;">{{ attendance.shift_name }}</td>
                            <td>{{ attendance.in_time | timeFormat }}</td>
                            <td>{{ attendance.out_time | timeFormat }}</td>
                            <td>{{ attendance.late_time }}</td>
                            <td>{{ attendance.ot_time }}</td>
                            <td>{{ attendance.status }}</td>
                        </tr>
                    </template>
                </v-simple-table>
            </v-col>
            <v-col cols="12" v-else id="reportContent">
                <v-row>
                    <v-col cols="9">
                        <v-simple-table dense class="employee_info">
                            <tr>
                                <th style="width: 25%;">E-ID:</th>
                                <th>{{ employee_statement.employee_code }}</th>
                            </tr>
                            <tr>
                                <th>Name:</th>
                                <th>{{ employee_statement.name }}</th>
                            </tr>
                            <tr>
                                <th>Designation:</th>
                                <th>{{ employee_statement.designation }}</th>
                            </tr>
                            <tr>
                                <th>Department:</th>
                                <th>{{ employee_statement.department }}</th>
                            </tr>
                            <tr>
                                <th>Joining date:</th>
                                <th>{{ employee_statement.joining_date }}</th>
                            </tr>
                        </v-simple-table>
                    </v-col>

                    <v-col cols="3">
                        <v-simple-table dense class="record_table statement_table">
                            <tr>
                                <th style="width: 60%;">TD</th>
                                <th>{{ employee_statement.total_days }}</th>
                            </tr>
                            <tr>
                                <th>P</th>
                                <th>{{ employee_statement.present }}</th>
                            </tr>
                            <tr>
                                <th>L</th>
                                <th>{{ employee_statement.late }}</th>
                            </tr>
                            <tr>
                                <th>LA</th>
                                <th>{{ employee_statement.late_absent }}</th>
                            </tr>
                            <tr>
                                <th>A</th>
                                <th>{{ employee_statement.absent }}</th>
                            </tr>
                            <tr>
                                <th>WH</th>
                                <th>{{ employee_statement.weekend }}</th>
                            </tr>
                            <tr>
                                <th>Holiday</th>
                                <th>{{ employee_statement.holiday }}</th>
                            </tr>
                            <tr>
                                <th>Leave</th>
                                <th>{{ employee_statement.leave }}</th>
                            </tr>
                        </v-simple-table>
                    </v-col>
                </v-row>
                <v-simple-table dense class="record_table">
                    <tr class="record_heading_tr">
                        <th>SL.</th>
                        <th>Attendance Date</th>
                        <th>Week Day</th>
                        <th>Working Day</th>
                        <th>In</th>
                        <th>Out</th>
                        <th>Late</th>
                        <th>OT</th>
                        <th>Status</th>
                    </tr>
                    <template v-for="record in records">
                        <template v-for="department in record.departments">
                            <tr v-for="attendance in department.attendances" :key="attendance.sl">
                                <td>{{ attendance.sl }}</td>
                                <td>{{ record.display_date }}</td>
                                <td>{{ record.day_name }}</td>
                                <td>{{ record.working_day }}</td>
                                <td>{{ attendance.in_time | timeFormat }}</td>
                                <td>{{ attendance.out_time | timeFormat }}</td>
                                <td>{{ attendance.late_time }}</td>
                                <td>{{ attendance.ot_time }}</td>
                                <td>{{ attendance.status }}</td>
                            </tr>
                        </template>
                    </template>
                    <tr>
                        <th colspan="6" style="text-align: right;">Total=</th>
                        <th>{{ employee_statement.total_late }}</th>
                        <th>{{ employee_statement.total_ot }}</th>
                        <th></th>
                    </tr>
                </v-simple-table>
            </v-col>
        </v-row>
    </v-container>

</template>
<script>
import utility from '../../mixins/utility.mixin';
export default {
    mixins: [utility],
    data() {
        return {
            date_from: this.toISOLocal().substr(0, 10),
            date_to: this.toISOLocal().substr(0, 10),
            designation: null,
            department: null,
            sections: [],
            section: null,
            lines: [],
            line: null,
            shift: null,
            employee: null,
            records: [],
            employee_statement: [],
            loading: false
        }
    },
    watch: {
        department(department) {
            this.getSections();
        },
        section(section) {
            this.getLines();
        },
        employee(e) {
            this.records = [];
            this.employee_statement = [];
        }
    },
    methods: {
        async getSections() {
            this.section = null;
            this.sections = [];
            if (this.department && this.department.id != undefined) {
                this.sections = await this.$store.dispatch('section/getSections', {
                    apiParams: {
                        departmentId: this.department.id
                    },
                    options: {
                        returnData: true,
                    }
                });
            }
        },
        async getLines() {
            this.line = null;
            this.lines = [];
            if (this.section && this.section.id != undefined) {
                this.lines = await this.$store.dispatch('line/getLines', {
                    apiParams: {
                        sectionId: this.section.id
                    },
                    options: {
                        returnData: true,
                    }
                });
            }
        },
        getEmployees() {
            this.employee = null;
            this.$store.dispatch('employee/getEmployees', {
                apiParams: {
                    departmentId: this.department?.id ?? null,
                    designationId: this.designation?.id ?? null,
                    sectionId: this.section?.id ?? null,
                    lineId: this.line?.id ?? null,
                    shiftId: this.shift?.id ?? null,
                },
            })
        },
        async getReports() {
            this.records = [];
            this.employee_statement = [];
            this.loading = true;

            let records = await this.$store
                .dispatch('attendance/getAttendanceRecords', {
                    apiParams: {
                        departmentId: this.department?.id ?? null,
                        designationId: this.designation?.id ?? null,
                        shiftId: this.shift?.id ?? null,
                        employeeId: this.employee?.id ?? null,
                        date_from: this.date_from,
                        date_to: this.date_to,
                    },
                    options: {
                        returnData: true
                    }
                });

            this.records = records.records;
            this.employee_statement = records.employee_statement;
            this.loading = false;
        },
        async print() {
            let date_text = '(' + this.date_from + ' to ' + this.date_to + ')';
            let title = 'Employee Attendance Statement ';
            if (this.employee == null || this.employee.id == undefined) {
                title = 'Attendance Reports ';
            }
            title += date_text;

            let company_profile = this.$store.getters['company/company'];

            // Get HTML to print from element
            const prtHtml = document.getElementById('reportContent').innerHTML;

            // Get all stylesheets HTML
            let stylesHtml = '';
            for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
                stylesHtml += node.outerHTML;
            }

            // Open the print window
            // const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
            const WinPrint = window.open('', 'PRINT', `height=${screen.height}, width=${screen.width}`);
            WinPrint.document.write(`<!DOCTYPE html>
                <html>
                <head>
                    ${stylesHtml}
                </head>
                <body>
                    <div data-app="true" class="v-application v-application--is-ltr theme--light" id="app">
                        <div class="container container--fluid">
                            <div class="row row--dense">
                                <div class="col-2 px-2">
                                    <img style="width: 100%; height: 50px;" src="${this.$store.state.host + company_profile.logo}">
                                </div>
                                <div class="col-10 px-2 text-center">
                                    <span class="font-weight-black">
                                        ${company_profile.name}
                                    </span>
                                    <br>
                                    <span>${company_profile.address}</span>
                                    <br>
                                    <span>
                                        Email:${company_profile.email}, Contact:${company_profile.phone}
                                    </span>
                                </div>
                                <div class="col-12 px-2 text-center">
                                    <p class="font-weight-black custom_header">
                                        ${title}
                                    </p>
                                </div>
                                <div class="col col-12 px-2">
                                    ${prtHtml}
                                </div>
                            </div>
                        </div>
                    </div>
                </body>
                </html>`);

            WinPrint.document.head.innerHTML += `
                    <style>
                        .v-data-table__wrapper{
                            overflow: unset;
                        }
                        .employee_info table{
                            padding-top: 20px !important;
                        }
                        .employee_info th{
                            padding: 0 !important;
                            border: none !important;
                            height: 20px !important;
                        }
                        .statement_table th{
                            height: 0 !important;
                        }
                        .record_heading_tr th{
                            text-align: center !important;
                        }
                        .custom_header{
                            background-color: #0000001f;
                            border-radius: 4px;
                            margin-bottom: 5px !important;
                        }
                        tr:hover{
                            background: unset !important;
                        }
                        .record_heading_tr:hover{
                            background: #4caf7773 !important;
                        }
                        @media print {
                            table.record_table { page-break-after:auto }
                            table.record_table tr    { page-break-inside:avoid; page-break-after:auto }
                            table.record_table td    { page-break-inside:avoid; page-break-after:auto }
                            table.record_table thead { display:table-header-group }
                            table.record_table tfoot { display:table-footer-group }
                        }
                    </style>
                `;
            WinPrint.focus();
            WinPrint.document.close();
            await new Promise(resolve => setTimeout(resolve, 2000));
            WinPrint.print();
            WinPrint.close();
        }

    },

}
</script>

<style scoped>
.record_table {
    margin-bottom: 5px;
}

.record_table table,
.record_table th,
.record_table td {
    border: 1px solid #dee2e6;
    border-collapse: collapse;
    padding: 0 !important;

}

.record_table th,
.record_table td {
    padding: 2px !important;
    font-size: 11px !important;
    text-align: center;
    height: 0 !important;
}

.employee_info th {
    text-align: left;
    font-size: 12px !important;
}

.record_heading_tr {
    background: #4caf7773;
}
</style>